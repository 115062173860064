import "./App.css";
import podium from "./components/assets/img/podium.png";
import Whatsapp from "./components/assets/img/Whatsapp.png";
import Instagram from "./components/assets/img/Instagram.png";
import Facebook from "./components/assets/img/Facebook.png";
import TikTok from "./components/assets/img/Tiktok.png";
import logo from "./components/assets/img/LogoGAIA-RGB.png";
import { useEffect, useState } from "react";

function App() {

  const [width, setWidth] = useState(window.innerWidth);

  const mobileScreen = width < 800;

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className="App">
      <div className="NavBar">
        <img src={logo} alt="podium" width="70px" height="70px" />
      </div>
      <div className="Body">
        <h1 style={{ fontSize: mobileScreen ? 30 : 60}}>
          <span className="Stars-icon">✨</span>
          <span>Estamos trabajando en nuestro sitio web</span>
          <span className="Stars-icon">✨</span>
        </h1>
        <div className="ButtonMenu-contianer" style={{marginBottom: mobileScreen ? -310 : -365}}>
          <a
            href="https://api.whatsapp.com/send?phone=573023413536&text=Bienvenido+a+Gaia+Glow"
            className="ButtonMenu"
          >
            Click para compras
            <img
              src={Whatsapp}
              alt="Whatsapp"
              width="50px"
              style={{ marginTop: -5 }}
            />
          </a>
          <div className="Social-media-container">
            <a href="https://www.instagram.com/gaiaglow_shop/">
              <img src={Instagram} alt="Instagram" width="50px" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100091401986098">
              <img src={Facebook} alt="Facebook" width="50px" />
            </a>
            <a href="https://www.tiktok.com/@gaiaglow_shop">
              <img src={TikTok} alt="TikTok" width="50px" />
            </a>
          </div>
        </div>
        <img src={podium} alt="podium" width={mobileScreen ? "470px" : "700px"} />
      </div>
    </div>
  );
}

export default App;
